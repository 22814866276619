import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LanguageIcon from '@mui/icons-material/Language';
import { NavLink } from 'react-router-dom';

const Navigation = (props) => {
    return (
        <div className="app-navigation">
            <div className='app-navigation__item'>
                <NavLink
                    activeClassName="app-navigation__item--seleccionado"
                    to="/inicio"><span className="circulo"></span><span className="app-navigation__item-description">
                        Inicio
                    </span>
                </NavLink >
            </div>
            <div className='app-navigation__item' >
                {/**
                 * 
                <NavLink activeClassName="app-navigation__item--seleccionado" to="/productos">
                 */}

                    <span className="circulo"></span >
                    <span className="app-navigation__item-description">
                        Productos y servicios
                    </span>
                {/**
                 * 
                </NavLink >
                 */}
            </div>
            <div className='app-navigation__item'   >
                {/*
                 * 
                <NavLink activeClassName="app-navigation__item--seleccionado" to="/proveedores">
                 */}
                    <span className="circulo"></span>
                    <span className="app-navigation__item-description">
                        Proveedores
                    </span>
                {/*
                 * 
                </NavLink >
                 */}
            </div >
            <div className='app-navigation__item' >
                <NavLink activeClassName="app-navigation__item--seleccionado" to="/contacto">
                    <span className="circulo"></span><span className="app-navigation__item-description">
                        Contacto
                    </span>
                </NavLink >
            </div>
            <div className='app-navigation__item' >
                {/*
                 * 
                <NavLink activeClassName="app-navigation__item--seleccionado" to="/contacto">
                 */}
                    <span className="app-navigation__item-icon"><MailOutlineIcon></MailOutlineIcon></span>
                {/*
                 * 
                </NavLink >
                 */}
            </div>
            <div className='app-navigation__item'><span className="app-navigation__item-icon"><LanguageIcon></LanguageIcon></span></div>
        </div >
    )
}

export default Navigation;
