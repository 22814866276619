import React from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Inicio() {
    return (
        <div className='app-inicio'>
            <Carousel showArrows={false} showThumbs={false} showStatus={false} width="100%" autoPlay={true} interval={3000}>
                <div>

                    <img className="custom-img" src={require('../../assets/carrusel/step3.png').default} alt="step1" />
                    <div className="legend-container">
                        <p className="custom-legend">Interruptor de Restablecimiento VacuFuse® II<br />
                            Mejore la confiabilidad. Potencie la satisfaccion del cliente. <br />
                        </p>
                    </div>
                </div>
                <div>
                    <img className="custom-img" src={require('../../assets/carrusel/step2.png').default} alt="step1" />
                    <div className="legend-container">
                        <p className="custom-legend">Reconectador Montado en Cortacircuito TripSaver® II <br />
                            Ahorre dinero. Aumente la confiabilidad.<br />
                        </p>
                    </div>
                </div>
                <div>
                    <img className="custom-img" src={require('../../assets/carrusel/step1.png').default} alt="step1" />
                    <div className="legend-container">
                        <p className="custom-legend">Interruptor de Fallas IntelliRuper® PulseCloser® </p>
                        <p>Una manera de buscar fallas y mejorar la confiabilidad de los alimentadores de
                            distribucion que ofrece bajo estres.
                        </p>
                    </div>
                </div>
                {/* 
                    <div>
                        <img style={{"maxHeight":"100%"}} src={require('../../assets/carrusel/step4.png').default} alt="step1" />
                        <p className="layer">Fusibles de Potencia SM y SMD®: Transmision en Exteriores <br />
                            Proteccion contra toda la gama de fallas para sistemas entre 4.16 kV a 138 kV.<br />
                        </p>
                    </div>                
                    */}
            </Carousel >
        </div >
    )
}

export default Inicio;
