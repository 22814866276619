import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import { useState } from 'react';

function Proveedores() {
    const [proveedores] = useState([
        {
            imagen: '/assets',
            nombre: 'Proveedor1'
        },
        {
            imagen: '/assets',
            nombre: 'proveedor2'
        },
        {
            imagen: '/assets',
            nombre: 'proveedor3'
        },
        {
            imagen: '/assets',
            nombre: 'Proveedor4'
        },
        {
            imagen: '/assets',
            nombre: 'proveedor5'
        },
        {
            imagen: '/assets',
            nombre: 'proveedor6'
        },
        {
            imagen: '/assets',
            nombre: 'Proveedor7'
        },
        {
            imagen: '/assets',
            nombre: 'proveedor8'
        },
        {
            imagen: '/assets',
            nombre: 'proveedor9'
        },
        {
            imagen: '/assets',
            nombre: 'Proveedor10'
        },
        {
            imagen: '/assets',
            nombre: 'proveedor11'
        },
        {
            imagen: '/assets',
            nombre: 'proveedor12'
        }
    ]);

    return (
        <div className="app-proveedores">
            <Grid container spacing={0} className="app-proveedores__container">
                {proveedores.map((proveedor) => (
                    <Grid item xs={12} md={4} className="app-proveedores__proveedor">
                        <Item className="app-proveedores__proveedor-item"></Item>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

export default Proveedores;
