import { Grid, Typography } from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import WhatsappIcon from '@mui/icons-material/WhatsApp';
import { makeStyles } from '@mui/styles';
import { Box } from "@mui/system";

function Contacto() {

    const useStyles = makeStyles(theme => ({
        gridRoot: {
            color: "#b1afaf",
            fontFamily: `MuktaRegular, Roboto, sans-serif`,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'

        },
    }));

    const classes = useStyles();
    return (
        <div className="app-contacto">
            <Grid container spacing={0} className="app-contacto__container">
                <Grid item xs={12} md={4} className="app-contacto__telefono" classes={{ root: classes.gridRoot }}>
                    <Box className="app-contacto__telefono-item">
                        <div className="app-contacto__telefono-icon-container">
                            <PhoneIcon style={{ fill: '#FFFFFF' }}></PhoneIcon>
                        </div>
                        <div className="app-contacto__phone-content">
                            <div>
                                <Typography variant="p">
                                    <b>PBX: (606) 321 61 61</b>
                                </Typography>
                            </div>
                            <div>

                                <Typography variant="p">
                                    <b>Celular: 320 6943210</b>
                                </Typography>
                            </div>

                        </div>

                    </Box>
                </Grid>
                <Grid item xs={12} md={4} className="app-contacto__email" classes={{ root: classes.gridRoot }}>
                    <Box className="app-contacto__email-item">
                        <div className="app-contacto__telefono-icon-container">
                            <EmailIcon style={{ fill: '#FFFFFF' }}></EmailIcon>
                        </div>
                        <div className="app-contacto__telefono-content">
                            <div>
                            <Typography variant="p">
                            <div>
                            <p style={{textAlign: "center"}}>
                            <b>info@bamycia.com
                                comercial@bamycia
                                ventas@bamycia.com
                                administrativa@bamycia
                                contabilidad@bamycia
                         </b>
                     </p>
                </div>
                                
                                </Typography>
                            </div>
                        </div>


                    </Box>
                </Grid>
                <Grid item xs={12} md={4} className="app-contacto__whatsapp" classes={{ root: classes.gridRoot }}>
                    <Box className="app-contacto__whatsapp-item">
                        <div className="app-contacto__telefono-icon-container">
                            <WhatsappIcon style={{ fill: '#FFFFFF' }}></WhatsappIcon>
                        </div>
                        <div className="app-contacto__whatsapp-content">
                            <div>
                                <Typography variant="p">
                                    <b>Ventas: 320 6943210</b>
                                 </Typography>
                            </div>

                            <div>
                                <Typography variant="p">
                                    <b>Comercial: 3104556967</b>
                                    </Typography>
                             </div>
                        </div>

                    </Box>
                </Grid>

            </Grid>
        </div>

    )
}

export default Contacto;
