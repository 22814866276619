import { createTheme } from "@mui/material"


const Theme = createTheme({
  typography: {
    body1: {
      fontFamily: `MuktaRegular, Roboto, sans-serif`
    }
  },
  palette: {
    light: '#0066ff',
    main: '#0044ff',
  },
})

export default Theme