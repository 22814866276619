import './App.scss';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import React, { useState } from 'react';
import Header from './components/Header/Header';
import Navigation from './components/Navigation/Navigation';
import Inicio from './components/Inicio/Inicio';
import Productos from './components/Productos/Productos';
import Proveedores from './components/Proveedores/Proveedores';
import Contacto from './components/Contacto/Contacto';
import Nosotros from './components/Nosotros/Nosotros';
import {
    Switch,
    Route
} from "react-router-dom";
import { ThemeProvider } from '@mui/material';
import Theme from './theme/Theme';
import { useLocation } from 'react-router-dom';

const App = props => {
    const [seccionActual, setSeccionActual] = useState('inicio');
    const location = useLocation();

    const getAppBackground = () => {
        let fondo = null

        if (location) {

            switch (location.pathname) {
                case '/inicio':
                    fondo = 'fondo-inicio'
                    break;
                case '/contacto':
                    fondo = 'fondo-contacto'
                    break;
                case '/nosotros':
                    fondo = 'fondo-nosotros'
                    break;
                default:
                    fondo = 'fondo-default'
                    break;
            }
        }

        return fondo;

    }

    // Declare a new state variable with the "useState" Hook
    const [width, setWidth] = useState(window.innerWidth);
    const [pantallaChiquita, setPantallaChiquita] = useState(false);
    const breakpoint = 950;

    React.useEffect(() => {
        const handleWindowResize = () => {
            setWidth(window.innerWidth);
            console.log(window.innerWidth)
            let smallScreen = window.innerWidth < breakpoint ? true : false;
            console.log(smallScreen, window.innerWidth)
            setPantallaChiquita(smallScreen);
        }
        window.addEventListener("resize", handleWindowResize);

        // Return a function from the effect that removes the event listener
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <ThemeProvider theme={Theme}>
            <div className={`app ${getAppBackground()}`}>
                <Header pantallaChiquita={pantallaChiquita} seccionActual={seccionActual} setSeccionActual={setSeccionActual}></Header>
                <div className="app-container">
                    <Grid style={{
                        "justifyContent": "center",
                        "alignItems": "center", "display": "flex"
                    }} container spacing={2}>
                        {pantallaChiquita ? `` :
                            <Grid
                                style={{
                                    "display": "flex",

                                }}
                                item xs={2} >

                                <Navigation

                                    seccionActual={seccionActual} setSeccionActual={setSeccionActual}></Navigation>
                            </Grid>}
                        <Grid item xs={10} className="app-content" >
                            <Switch>
                                <Route path="/inicio">
                                    <Inicio />
                                </Route>
                                <Route path="/productos">
                                    <Productos />
                                </Route>
                                <Route path="/proveedores">
                                    <Proveedores />
                                </Route>
                                <Route path="/contacto">
                                    <Contacto />
                                </Route>
                                <Route path="/nosotros">
                                    <Nosotros />
                                </Route>
                                <Route path="/">
                                    <Inicio />
                                </Route>
                            </Switch>
                        </Grid>
                    </Grid>
                </div>

                <div className="app-footer" style={{
                                    "display": "flex",
                                    "align-items": "center",
                                    "padding": "0.25rem 1rem"

                                }}>
                    <Grid item xs={4}>
                        <a style={{
                        }} href='https://www.dropbox.com/s/g6tul147dmztbu8/PR-TA-07 REV00.pdf?dl=0'>Políticas de privacidad</a>

                    </Grid>
                    <Grid item xs={8}>
                        <Item className="app-footer__container">
                            <div className="app-footer__wrapper">
                                <div className="app-footer__direccion">PEREIRA: Calle 14 No. 23-126, 660003, PEREIRA</div>
                                <div className="app-footer__celular">PBX: (606) 321 61 61 Celular: 320 634 3210</div>


                            </div>
                        </Item>
                    </Grid>
                </div>
            </div >
        </ThemeProvider>
    );
}

export default App;
