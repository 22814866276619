import Grid from '@mui/material/Grid';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import Item from '@mui/material/Grid';
import { NavLink } from 'react-router-dom';
import {
    AppBar,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Toolbar
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import { Box } from '@mui/system';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';

const Header = (props) => {
    const [mobileOpen, setMobileOpen] = useState(false)
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const useStyles = makeStyles(theme => ({
        root: {
            color: "#b1afaf",
            fontFamily: `MuktaRegular, Roboto, sans-serif`,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            textTransform: 'uppercase',
            fontSize: '0.85rem',

        },
        toolbarRoot: {
            color: "#b1afaf",
            backgroundColor: "black",
            border: '1px solid',
            textDecoration: 'solid',
        },
        paper: {
            backgroundColor: "black!important",
            border: '1px solid #b1afaf'
        },
        link: {
            textDecoration: 'none'
        },
    }));

    const classes = useStyles();

    return (
        <div className="app-header">
            {props.pantallaChiquita ?
                <Box sx={{ display: 'flex' }} >
                    <AppBar position="fixed">
                        <Toolbar classes={{
                            root: classes.toolbarRoot,
                        }}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                sx={{
                                    marginRight: '36px'
                                }}
                            >
                                <MenuIcon onClick={() => handleDrawerToggle()} />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Drawer classes={{
                        root: classes.root,
                        paper: classes.paper
                    }} variant="temporary" open={mobileOpen}>
                        <Box classes={{
                            root: classes.root
                        }} >
                            <div className="app-logo">
                                <img src={require('../../assets/images/BAM.svg').default} alt="logo" />
                                <IconButton >
                                    <ChevronLeftIcon style={{ fill: "#b1afaf" }} onClick={() => handleDrawerToggle()}></ChevronLeftIcon>
                                </IconButton>
                            </div>
                        </Box>
                        <Divider />
                        <List classes={{
                            root: classes.root
                        }} >
                            {[
                                {
                                    url: 'inicio',
                                    descripcion: 'Inicio'
                                },
                                {
                                    url: 'productos',
                                    descripcion: 'productos y servicios'
                                },
                                {
                                    url: 'proveedores',
                                    descripcion: 'Proveedores'
                                },
                                {
                                    url: 'contacto',
                                    descripcion: 'Contacto'
                                },
                                {
                                    url: 'nosotros',
                                    descripcion: 'Nosotros'
                                },
                            ].map((item, index) => (

                                <ListItem button key={item}>
                                    <NavLink
                                        className="app-navigation__item app-navigation__item--no-padding"
                                        activeClassName="app-navigation__item--seleccionado"
                                        to={item.url}
                                        onClick={() => handleDrawerToggle()}>
                                        <span className="circulo-pequeno"></span> <ListItemText classes={{
                                            primary: classes.root
                                        }} primary={item.descripcion}></ListItemText>
                                    </NavLink>

                                </ListItem>

                            ))}
                        </List>
                    </Drawer>
                </Box>
                : <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Item>
                            <div className="app-logo">
                                <img src={require('../../assets/images/BAM.svg').default} alt="logo" />
                            </div>
                        </Item>
                    </Grid>
                    <Grid item xs={9}>
                        <Item className="app-header__actions-container">
                            <div>
                                <NavLink activeClassName="app-navigation__item--seleccionado" to="/nosotros">
                                    <span className="app-navigation__item-description">
                                        Nosotros
                                    </span>
                                </NavLink>
                            </div>
                            <div className='app-header__portal-clientes' >Portal Clientes <AccountCircleIcon></AccountCircleIcon></div>
                        </Item>
                    </Grid>

                </Grid>
            }

        </div>
    )
}

export default Header;
