import { Grid } from "@mui/material";

function Nosotros() {
    return (
        <div className="app-nosotros">
            <Grid container xs={20} md={14} spacing={0} className="app-nosotros__container">
                <div item className="app-nosotros__content">
                    <p>En 1940 inició operaciones comerciales en la ciudad de Pereira, Colombia, el Señor Bernardo Angel Marulanda, para luego en 1969 constituir la sociedad BERNARDO ANGEL M. Y CIA. LTDA (BAM Y CIA LTDA) con el objeto de desarrollar la importación, distribución, representación, compra y venta de maquinaria industrial, equipos y materiales eléctricos y electrónicos, consolidándose como uno de los principales suministradores de equipos y soluciones para la creciente industria cafetera de la región. <br />
                        A hoy BERNARDO ANGEL M. Y CIA. SAS hace presencia directa en las principales ciudades  del país especialmente en el Eje Cafetero a través de su sede principal en Pereira e indirectamente por medio de nuestros distribuidores y aliados comerciales a lo largo de todo Colombia.
                        La meta diaria de nuestra organización es proveer una asesoría de alta calidad para la especificación óptima de productos y servicios para el sector eléctrico. Contamos con un grupo humano conformado por personal especializado en las áreas administrativa, técnica, de mercadeo y ventas, algunos de ellos con más de 30 años de experiencia en la empresa. <br />
                        Periódicamente el personal técnico visita nuestros proveedores y representados, para discutir problemas de diseño, instalación, aplicación y mantenimiento de los nuevos equipos; además asistimos a cursos de capacitación en aspectos técnicos y administrativos, asegurando así la idoneidad y actualización permanente de nuestro personal.<br />
                        Por más de 80 años, Bernardo Angel M y Cia SAS ha desarrollado importantes relaciones comerciales con Compañías del Sector Eléctrico e hidráulico de reconocimiento nacional e internacional por su seriedad, cumplimiento, liderazgo tecnológico y calidad de sus productos. <br />
                        Nuestro portafolio de productos y soluciones se enmarca principalmente en: <br />
                        ◾ Equipos de maniobra, protección y seccionamiento en media y alta tensión bajo la marca S&C Electric Company la cual representamos y distribuimos para Colombia.<br />
                        ◾ Aisladores poliméricos hasta 500kV, conectores automáticos y descargadores de sobretensión poliméricos bajo la marca MacLean Power Systems la cual representamos para Colombia.<br />
                        ◾ Especificación y suministro de bombas, motobombas, electrobombas, equipos de presión, equipos contra incendio y accesorios.<br />
                        ◾ Ventiladores industriales, equipos de maniobra y protección en baja tensión de múltiples marcas de alto reconocimiento.<br />
                        ◾ Grupos electrógenos.<br />
                    </p>
                </div>
            </Grid>
        </div>
    )
}

export default Nosotros;
